import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {HttpClientModule} from '@angular/common/http';

import {
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
  USE_DEVICE_LANGUAGE,
} from '@angular/fire/compat/auth';
import {
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR,
} from '@angular/fire/compat/database';
import {
  AngularFirestoreModule,
  SETTINGS as FIRESTORE_SETTINGS,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/functions';
import {
  AngularFireStorageModule,
  USE_EMULATOR as USE_STORAGE_EMULATOR,
} from '@angular/fire/compat/storage';
// import {
//   AngularFireRemoteConfigModule,
//   SETTINGS as REMOTE_CONFIG_SETTINGS,
//   DEFAULTS as REMOTE_CONFIG_DEFAULTS,
// } from '@angular/fire/compat/remote-config';
// import {
//   AngularFirePerformanceModule,
//   PerformanceMonitoringService,
// } from '@angular/fire/compat/performance';
import {CommonModule} from '@angular/common';
import {getAnalytics, provideAnalytics} from '@angular/fire/analytics';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getPerformance, providePerformance} from '@angular/fire/performance';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {AngularFireModule, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {
  DEBUG_MODE as ANALYTICS_DEBUG_MODE,
  APP_NAME,
  APP_VERSION,
  AngularFireAnalyticsModule,
  COLLECTION_ENABLED,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {connectDatabaseEmulator, getDatabase, provideDatabase} from '@angular/fire/database';
import {connectFirestoreEmulator, getFirestore, provideFirestore} from '@angular/fire/firestore';
import {
  FunctionsModule,
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {getRemoteConfig, provideRemoteConfig} from '@angular/fire/remote-config';
import {connectStorageEmulator, getStorage, provideStorage} from '@angular/fire/storage';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {LottieModule} from 'ngx-lottie';
import {ToastrModule} from 'ngx-toastr';
import {SwiperModule} from 'swiper/angular';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  ActionContentDirective,
  ExpandContentDirective,
  ItemActionsContentDirective,
  ListViewComponent,
} from './components/list-view/list-view.component';
import {LoaderComponent} from './components/loader/loader.component';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {AddAdminUserModalComponent} from './modals/add-admin-user-modal/add-admin-user-modal.component';
import {AddProductsStrategySelectModalComponent} from './modals/add-products-modal/add-products-modal.component';
import {ConfirmModalComponent} from './modals/confirm-modal/confirm-modal.component';
import {DeleteUserModalComponent} from './modals/delete-user-modal/delete-user-modal.component';
import {EditCurrencyModalComponent} from './modals/edit-currency-modal/edit-currency-modal.component';
import {EditStoreModalComponent} from './modals/edit-store-modal/edit-store-modal.component';
import {AdminUsersComponent} from './pages/admin-users/admin-users.component';
import {CmsComponent} from './pages/cms/cms.component';
import {LegalContentManagerComponent} from './pages/cms/legal-content-manager/legal-content-manager.component';
import {MediaFileManagerComponent} from './pages/cms/media-file-manager/media-file-manager.component';
import {PlatformTextPropertiesFormComponent} from './pages/cms/platform-text-properties-form-component/platform-text-properties-form-component.component';
import {CurrenciesComponent} from './pages/currencies/currencies.component';
import {DeleteUserComponent} from './pages/delete-user/delete-user.component';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './pages/login/login.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {SmsCreationComponent} from './pages/sms-creation/sms-creation.component';
import {StoresComponent} from './pages/stores/stores.component';
import {Bs5UnixCronModule} from '@sbzen/ng-cron';
import {CronExpressionEditModalComponent} from './modals/cron-expression-edit-modal/cron-expression-edit-modal.component';

enum EMULATOR_PORTS {
  RTDB = 8090,
  FIRESTORE = 8080,
  FUNCTIONS = 5001,
  STORAGE = 9199,
}

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    AppLayoutComponent,
    LoginComponent,
    HomeComponent,
    NotFoundComponent,
    ListViewComponent,
    ActionContentDirective,
    ExpandContentDirective,
    ItemActionsContentDirective,
    LoaderComponent,
    ConfirmModalComponent,
    AdminUsersComponent,
    AddAdminUserModalComponent,
    StoresComponent,
    EditStoreModalComponent,
    CurrenciesComponent,
    EditCurrencyModalComponent,
    DeleteUserComponent,
    DeleteUserModalComponent,
    SmsCreationComponent,
    MediaFileManagerComponent,
    CmsComponent,
    PlatformTextPropertiesFormComponent,
    LegalContentManagerComponent,
    AddProductsStrategySelectModalComponent,
    CronExpressionEditModalComponent,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    providePerformance(() => getPerformance()),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    CommonModule,
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    // AngularFirePerformanceModule,
    // AngularFireRemoteConfigModule,
    AngularFireAuthGuardModule,
    FunctionsModule,
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators && !environment.dockerCompose) {
        connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: true});
      }

      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, 'localhost', EMULATOR_PORTS.FIRESTORE);
      }

      return firestore;
    }),
    provideDatabase(() => {
      const database = getDatabase();
      if (environment.useEmulators && !environment.dockerCompose) {
        connectDatabaseEmulator(database, 'localhost', EMULATOR_PORTS.RTDB);
      }

      return database;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators && !environment.dockerCompose) {
        connectStorageEmulator(storage, 'localhost', EMULATOR_PORTS.STORAGE);
      }

      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', EMULATOR_PORTS.FUNCTIONS);
      }

      return functions;
    }),
    LottieModule.forRoot({player: () => import('lottie-web')}),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgbModule,
    NgSelectModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    FontAwesomeModule,
    NgbNavModule,
    Bs5UnixCronModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig},
    ScreenTrackingService,
    UserTrackingService,
    // PerformanceMonitoringService,
    {
      provide: FIRESTORE_SETTINGS,
      useValue: {
        ignoreUndefinedProperties: true,
        merge: true,
        experimentalForceLongPolling: environment.featureFlags.longPolling,
        experimentalAutoDetectLongPolling: !environment.featureFlags.longPolling,
      },
    },
    {provide: ANALYTICS_DEBUG_MODE, useValue: true},
    {provide: COLLECTION_ENABLED, useValue: true},
    {
      provide: USE_AUTH_EMULATOR,
      useValue:
        environment.useEmulators && !environment.dockerCompose
          ? ['http://localhost:9099']
          : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue:
        environment.useEmulators && !environment.dockerCompose
          ? ['localhost', EMULATOR_PORTS.RTDB]
          : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,

      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.FIRESTORE] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.FUNCTIONS] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue:
        environment.useEmulators && !environment.dockerCompose && environment.dockerCompose
          ? ['localhost', EMULATOR_PORTS.STORAGE]
          : undefined,
    },
    // {
    //   provide: REMOTE_CONFIG_SETTINGS,
    //   useFactory: () => (isDevMode() ? {minimumFetchIntervalMillis: 10_000} : {}),
    // },
    // {provide: REMOTE_CONFIG_DEFAULTS, useValue: {}},
    {provide: USE_DEVICE_LANGUAGE, useValue: true},
    {provide: APP_VERSION, useValue: '1.0.19'},
    {provide: APP_NAME, useValue: 'Client'},
  ],
})
export class AppModule {
  constructor() {
    console.log('angular started');
  }
}
