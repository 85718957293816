import {Component, OnInit, inject} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {UsersService} from './services/users.service';
import {Performance} from '@angular/fire/performance';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private perf = inject(Performance);
  isLoading = true;
  connectedUser;

  constructor(private usersService: UsersService) {
    this.connectedUser = this.usersService.getConnectedUserSync();
  }

  ngOnInit(): void {
    this.usersService
      .getConnectedUser()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((user) => {
        this.connectedUser = user;
      });

    this.perf.dataCollectionEnabled = true;
    this.perf.instrumentationEnabled = true;
  }
}
